@import './media-queries.scss';

footer {
    display: grid;
    grid-template-columns: auto auto auto auto;
    // margin: 150px 0px 0px 0px;
    position: absolute;

    @include xs {
        display: block;
    }

    @include sm {
        display: block;
    }

    @include md {}

    @include lg {}

    @include xl {}

    h3 {
        color: #FFF;

        font: {
            family: var(--def-font);
            size: var(--h5-fs) !important;
            weight: 400;
        }

        margin: 60px 0px 40px 0px;
    }

    h5 {
        color: #FFF;

        font: {
            family: var(--def-font) !important;
            size: 12px !important;
            style: normal;
            weight: 400;
        }

        line-height: normal;
        opacity: 0.4;
    }

    #video {
        width: 100%;
        mix-blend-mode: exclusion;
        object-fit: cover;
        object-position: left;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        opacity: .2;
        // object-position: 100px;
        z-index: -1;
    }

    .text {
        color: #FFF;
        font-family: var(--def-font);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 25.2px;
        margin: 0px 0px 40px 50px;

        @include xs {
            margin: 0px 0px 40px 30px;
        }

        @include sm {
            margin: 0px 0px 40px 30px;
        }

        @include md {
            font-size: 12px;
        }

        @include lg {}

        @include xl {}
    }

    .column-one {
        border-right: var(--footer-b);
        padding-right: var(--footer-p) !important;

        @include xs {
            border-right: none;
            border-bottom: var(--footer-b);
        }
    }

    .column-two {
        padding-left: var(--footer-p) !important;
    }

    .column-four {
        background: rgba(9, 14, 32, 0.5);
        backdrop-filter: blur(100px);
        grid-row-end: span 2;
    }

    .column-five {
        border-top: var(--footer-b);
        align-items: center;
        display: flex;
        justify-content: space-between;
        grid-column-start: 1;
        grid-column-end: 4;

        @include xs {
            border: none;
        }
    }

    .column-four h3 {
        margin-left: 70px;

        @include xs {
            margin-left: 30px;
        }

        @include sm {
            margin-left: 30px;
        }

        @include md {}

        @include lg {}

        @include xl {}
    }


    .logo {
        width: 102px;
        height: 29px;
        margin: 75px 0px 40px 50px;
        background-image: url('../../../public/images/logo-sato7.png');
        background-repeat: no-repeat;
        background-size: contain;

        @include xs {
            margin: 60px 0px 40px 30px;
        }

        @include sm {
            margin: 60px 0px 40px 30px;
        }

        @include md {}

        @include lg {}

        @include xl {}
    }

    p {
        color: #FFF;
        font-family: var(--def-font);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 25.2px;
        margin-bottom: 0px;

        @include xs {}

        @include sm {}

        @include md {
            font-size: 12px;
        }

        @include lg {}

        @include xl {}
    }



    .links {
        padding: 0;

        @include xs {
            padding: 0px 0px 0px 30px;
        }

        @include sm {
            padding: 0px 0px 0px 30px;
        }

        @include md {}

        @include lg {}

        @include xl {}

        ul {
            padding: 0;

            @include xs {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
            }

            li {
                list-style: none;
                margin-bottom: 10px;

                a {
                    font-family: var(--def-font);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.36px;
                    text-decoration: none;
                    color: #FFF;
                    opacity: .4;
                    display: inline-block;

                    &:hover {
                        color: #FFF;
                        opacity: 1;
                    }

                    @include xs {}

                    @include sm {}

                    @include md {
                        font-size: 12px;
                    }

                    @include lg {}

                    @include xl {}
                }
            }
        }


    }

    .contact {

        @include xs {
            padding: 0px 0px 0px 30px;
        }

        @include sm {
            padding: 0px 0px 0px 30px;
        }

        @include md {}

        @include lg {}

        @include xl {}

        h5 {
            margin: 0px;
        }

        ul {
            list-style: none;
            padding: 0;

            li {
                display: flex;
                align-items: center;
                margin-bottom: 40px;

                .icon-phone {
                    width: 30px;
                    height: 29px;
                    display: block;
                    background-image: url('../../../public/images/components/footer/icon-phone.svg');
                    background-repeat: no-repeat;
                    background-size: contain;
                }

                .icon-email {
                    width: 30px;
                    height: 29px;
                    display: block;
                    background-image: url('../../../public/images/components/footer/icon-email.svg');
                    background-repeat: no-repeat;
                    background-size: contain;
                }

                .icon-location {
                    width: 30px;
                    height: 29px;
                    display: block;
                    background-image: url('../../../public/images/components/footer/icon-location.svg');
                    background-repeat: no-repeat;
                    background-size: contain;
                }

            }
        }

    }

    form {
        padding: 0px 70px 10px 70px;

        @include xs {
            padding: 0px 30px 40px 30px;
        }

        @include sm {
            padding: 0px 30px 40px 30px;
        }

        @include md {}

        @include lg {}

        @include xl {}

    }

    .column-five {

        @include xs {
            flex-direction: column-reverse;
        }

        @include sm {
            flex-direction: column-reverse;
        }

        @include md {}

        @include lg {}

        @include xl {}
    }

    .copy {
        width: 50%;
        margin: 50px 0px 50px 40px;

        @include xs {
            width: 100%;
            text-align: center;
            margin: 50px 0px 50px 0px;
        }

        @include sm {
            width: 100%;
            text-align: center;
            margin: 50px 0px 50px 0px;
        }

        @include md {}

        @include lg {}

        @include xl {}
    }

    .social {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 30px;

        @include xs {
            padding-right: 0px;
        }

        @include sm {
            padding-right: 0px;
        }

        @include md {}

        @include lg {}

        @include xl {}

        .icon {
            width: 25px;
            height: 25px;
        }

        .icon-instagram {
            background-image: url('../../../public/images/icon-instagram.png');
            background-repeat: no-repeat;
            background-size: contain;
        }

        .icon-facebook {
            background-image: url('../../../public/images/icon-facebook.png');
            background-repeat: no-repeat;
            background-size: contain;
        }

        .icon-linkedin {
            background-image: url('../../../public/images/icon-linkedin.png');
            background-repeat: no-repeat;
            background-size: contain;
        }

        .icon-youtube {
            background-image: url('../../../public/images/icon-youtube.png');
            background-repeat: no-repeat;
            background-size: contain;
        }

        .icon-twitter {
            background-image: url('../../../public/images/icon-twitter.png');
            background-repeat: no-repeat;
            background-size: contain;
        }

    }

}