// media aliases and breakpoints
$screen-iphone-min: 390px;
$screen-iphone-max: 599px;

$screen-sm-min: 600px;
$screen-sm-max: 979px;
$screen-md-min: 980px;
$screen-md-max: 1399px;
$screen-lg-min: 1400px;
$screen-lg-max: 2100px;
$screen-xl-min: 1101px;
$screen-xl-max: 1920px;
$screen-xxl-min: 1921px;
$screen-xxl-max: 3000px;

$screen-xs-max: 599px;

@mixin custom-lg {
	@media screen and (min-width: 1410px) and (max-width: 1589px) {
		@content;
	}
}

// media devices
@mixin ip {
	@media screen and (max-width: #{$screen-iphone-max}) and (max-width: #{$screen-iphone-max}) {
		@content;
	}
}

@mixin xs {
	@media screen and (max-width: #{$screen-xs-max}) {
		@content;
	}
}

@mixin sm {
	@media screen and (min-width: #{$screen-sm-min}) and (max-width: #{$screen-sm-max}) {
		@content;
	}
}

@mixin md {
	@media screen and (min-width: #{$screen-md-min}) and (max-width: #{$screen-md-max}) {
		@content;
	}
}

@mixin lg {
	@media screen and (min-width: #{$screen-lg-min}) and (max-width: #{$screen-lg-max}) {
		@content;
	}
}

@mixin xl {
	@media screen and (min-width: #{$screen-xl-min}) and (max-width: #{$screen-xl-max}) {
		@content;
	}
}

@mixin xxl {
	@media screen and (min-width: #{$screen-xxl-min}) and (max-width: #{$screen-xxl-max}) {
		@content;
	}
}

// media lt queries
@mixin lt-sm {
	@media screen and (max-width: #{$screen-xs-max}) {
		@content;
	}
}

@mixin lt-md {
	@media screen and (max-width: #{$screen-sm-max}) {
		@content;
	}
}

@mixin lt-lg {
	@media screen and (max-width: #{$screen-md-max}) {
		@content;
	}
}

@mixin lt-xl {
	@media screen and (max-width: #{$screen-lg-max}) {
		@content;
	}
}

@mixin lt-xxl {
	@media screen and (max-width: #{$screen-xl-max}) {
		@content;
	}
}

// media gt queries
@mixin gt-xs {
	@media screen and (min-width: #{$screen-sm-min}) {
		@content;
	}
}

@mixin gt-sm {
	@media screen and (min-width: #{$screen-md-min}) {
		@content;
	}
}

@mixin gt-md {
	@media screen and (min-width: #{$screen-lg-min}) {
		@content;
	}
}

@mixin gt-lg {
	@media screen and (min-width: #{$screen-xl-min}) {
		@content;
	}
}

@mixin gt-xl {
	@media screen and (min-width: #{$screen-xxl-min}) {
		@content;
	}
}

@mixin lg-h-768 {
	@media screen and (min-width: $screen-lg-min) and (max-height: 768px) {
		@content;
	}
}

@mixin lg-h-2000 {
	@media screen and (min-width: $screen-lg-min) and (min-height: 769px) and (max-height: 1400px) {
		@content;
	}
}
