#politica-privacidade{
    scroll-behavior: smooth;
    .container {
        display: flex;
        width: 100%;
        max-width: 1440px;
        padding-top:120px;
        padding-bottom:160px;
    }

    li{
        color:var(--c-white);
    }
}