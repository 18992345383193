@import "./media-queries.scss";

.socialVert,
.main-social {
	height: 50vh;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	flex-direction: column;
	padding-left: 20px;
	position: absolute;
	z-index: 1;
	top: 25vh;

	.icon {
		width: 30px;
		height: 30px;
	}

	.icon-instagram {
		background-image: url("../../../public/images/icon-instagram.png");
		background-repeat: no-repeat;
		background-size: contain;
	}
	.icon-facebook {
		background-image: url("../../../public/images/icon-facebook.png");
		background-repeat: no-repeat;
		background-size: contain;
	}
	.icon-linkedin {
		background-image: url("../../../public/images/icon-linkedin.png");
		background-repeat: no-repeat;
		background-size: contain;
	}
	.icon-youtube {
		background-image: url("../../../public/images/icon-youtube.png");
		background-repeat: no-repeat;
		background-size: contain;
	}
	.icon-twitter {
		background-image: url("../../../public/images/icon-twitter.png");
		background-repeat: no-repeat;
		background-size: contain;
	}
}

.socialVert{
	@include xs {
		display: none;    
	}
	@include sm {
		display: none;
	}
}