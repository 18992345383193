@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

::-webkit-scrollbar {
	width: 0px;
	background: transparent;
	/* make scrollbar transparent */
}

#home {
	overflow-x: clip;
	position: relative;

	>header {
		min-height: 100vh;
		position: relative;

		#sticky-video {
			bottom: 0;
			height: 100vh;
			margin-bottom: -105vh;
			mix-blend-mode: exclusion;
			position: sticky;
			overflow: hidden;
			width: 100vw;
			z-index: 0;

			&::after {
				content: "";
				display: block;
				background-image: linear-gradient(160deg,
						rgba(7, 11, 24, 0) 0%,
						rgba(7, 11, 24, 0) 75%,
						rgba(7, 11, 24, 1) 85%);
				height: 100vh;
				mix-blend-mode: normal;
				position: absolute;
				top: 0;
				width: 100vw;
			}

			.tag-saiba-mais {
				bottom: 10%;
				position: absolute;
				right: 10%;
				top: 80vh;
				z-index: 1;

				@include media-breakpoint-only(xs) {
					left: 15px;
					top: calc(100vh - 107px);
					right: unset;
				}
			}
		}

		#video {
			height: 100vh;
			object-fit: cover;
			position: absolute;
			right: -25%;
			width: 100%;
		}

		#video {
			width: 100%;
			height: 100vh;
			mix-blend-mode: exclusion;
			object-fit: cover;
			position: absolute;
			right: -25%;
			z-index: 0;

			@include media-breakpoint-only(xs) {
				width: 100%;
				height: 100%;
				opacity: 0.4;
			}
		}

		.container-fluid {
			scroll-behavior: smooth;

			/*&:before {
				background: url("../../../public/images/pages/home/home-header-video-bg-lines.webp");
				background-size: contain;
				background-repeat: no-repeat;
				content: "";
				display: block;
				height: 100vh;
				mix-blend-mode: color-dodge;
				position: absolute;
				object-fit: cover;
				right:-25%;
				width: 100%;
				z-index: 1;
			}*/
			&>.row {
				min-height: 100vh;

				&:last-child {
					align-content: center;

					@keyframes animation {
						0% {
							transform: translateX(-1000px);
							opacity: 0;
						}

						50% {
							transform: translateX(0px);
							opacity: 1;
						}
					}

					@media (prefers-reduced-motion: no-preference) {
						animation-name: animation;
						animation-duration: 1s;
						animation-iteration-count: 1;
						animation-timing-function: ease-in-out();
					}
				}
			}

		}

		.content {
			height: 100vh;
			width: 40%;
			display: flex;
			justify-content: center;
			flex-direction: column;
			position: absolute;
			z-index: 1;
			top: 0;

			@include media-breakpoint-only(xs) {
				width: calc(100% - 15px);
			}

			@include media-breakpoint-between(sm, lg) {
				width: calc(75% - 15px);
			}

			@include media-breakpoint-only(lg) {
				width: 50%;
			}

			@include media-breakpoint-only(xxl) {
				width: 35%;
			}

			h1 {
				margin-bottom: 50px;
				font-family: var(--def-h-font);
				font-weight: bold;
				color: #fff;
				font-size: 55px;

				span {
					background: linear-gradient(77deg, #e41b4b 0%, #d94f4f 100%);
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
				}
			}

			.feat {
				display: grid;
				grid-template-columns: 2fr 20fr;
				max-height: max-content;

				@include media-breakpoint-only(xs) {
					/*display: none;*/
					grid-template-columns: none;
				}

				&:before {
					aspect-ratio: 1 / 1;
					content: "";
					background-image: url('data:image/svg+xml,<svg width="58" height="90" viewBox="0 0 58 90" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M0 75L31.7089 7L35.3773 7L3.66842 75H0Z" fill="url(%23paint0_linear_733_4467)"/><path fill-rule="evenodd" clipRule="evenodd" d="M11.8706 90L53.8383 0H57.5067L15.539 90H11.8706Z" fill="url(%23paint1_linear_733_4467)"/><defs><linearGradient id="paint0_linear_733_4467" x1="2.45676" y1="58.7556" x2="26.961" y2="58.6609" gradientUnits="userSpaceOnUse"><stop stop-color="%23E41B4B"/><stop offset="1" stop-color="%23D94F4F"/></linearGradient><linearGradient id="paint1_linear_733_4467" x1="15.0398" y1="68.5" x2="46.6499" y2="68.3809" gradientUnits="userSpaceOnUse"><stop stop-color="%23E41B4B"/><stop offset="1" stop-color="%23D94F4F"/></linearGradient></defs></svg>');
					background-repeat: no-repeat;
					background-size: contain;
					display: flex;
					height: 100%;
					width: fit-content;

					@include media-breakpoint-only(xs) {
						display: none;
					}
				}

				& p,
				& span {
					color: var(--c-white);
					display: grid;
					font-size: 24px;
					font-weight: 200;
					margin-left: 15px;

					@include media-breakpoint-only(xs) {
						padding-left: 0;
					}
				}
			}
		}

		&:after {
			background: {
				image: url(../../../public/images/pages/home/bg-left-star.webp);
				position: left;
				repeat: no-repeat;
			}

			content: "";
			display: block;
			height: 2095px;
			mix-blend-mode: hard-light;
			position: absolute;
			top: 0vh;
			width: 50vw;
			z-index: 0;

			@include media-breakpoint-only(xs) {
				background-position: left center;
				background-size: cover;
				left: 0;
				position: absolute;
				height: 100vh;
				width: 100vw;
				top: 0;
			}
		}
	}

	.tag-17-anos {
		aspect-ratio: 1 / 1;
		height: 13%;
		width: 13%;

		@include media-breakpoint-only(xs) {
			height: 150px;
			width: 150px;
		}
	}

	#section1 {
		height: 80vh;

		@include media-breakpoint-only(xs) {
			height: max-content;
			margin-top: 3rem;
		}
	}

	#section2 {
		align-items: center;
		background-image: url("../../../public/images/pages/home/bg-stars-circles.svg");
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center bottom;
		display: flex;
		min-height: 100vh;
		margin-top: 140px;
		width: 100%;

		@include media-breakpoint-only(xs) {
			margin-top: 60px;
		}

		&:before {
			background: {
				image: url(../../../public/images/pages/home/bg-right-star.webp);
				position: center right;
				repeat: no-repeat;
			}

			content: "";
			display: block;
			height: 2095px;
			mix-blend-mode: hard-light;
			position: absolute;
			right: 0;
			width: 50vw;
			z-index: 0;
		}

		.bg-effect {
			width: 100%;
			height: 100vh;
			background-image: url("../../../public/images/pages/home/home-bg-effect-right.svg");
			background-repeat: no-repeat;
			background-size: contain;
			background-position: right;
			position: absolute;
		}

		.grid-container {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
			grid-template-rows: 200px 200px 100px;
			gap: 5px;

			@include media-breakpoint-only(xs) {
				align-items: center;
				grid-auto-flow: column;
				grid-gap: 30px;
				grid-template-columns: max-content;
				grid-template-rows: max-content;
				justify-content: center;
			}

			.grid-item.item1 {
				left: -25px;
			}

			.grid-item.item2 {
				right: -25px;
			}

			.grid-item.circle {
				top: -150px;
			}

			.grid-item.text-results {
				top: -130px;
			}

			.grid-item.item3 {
				left: -80px;
				top: 55px;
			}

			.grid-item.item4 {
				right: -80px;
				top: 55px;
			}

			.grid-item.item5 {
				top: 55px;
			}

			.grid-item.item6 {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				top: 150px;
				z-index: 1;
			}

			.number-10 {
				width: 55px;
				height: 19px;
				background-image: url("../../../public/images/pages/home/10+.svg");
				background-repeat: no-repeat;
				background-size: contain;
			}

			.number-100 {
				width: 77px;
				height: 19px;
				background-image: url("../../../public/images/pages/home/100+.svg");
				background-repeat: no-repeat;
				background-size: contain;
			}

			.number-100m {
				width: 109px;
				height: 19px;
				background-image: url("../../../public/images/pages/home/100M+.svg");
				background-repeat: no-repeat;
				background-size: contain;
			}

			.number-500 {
				width: 78px;
				height: 19px;
				background-image: url("../../../public/images/pages/home/500+.svg");
				background-repeat: no-repeat;
				background-size: contain;
			}

			.number-3bi {
				width: 66px;
				height: 19px;
				background-image: url("../../../public/images/pages/home/3bi+.svg");
				background-repeat: no-repeat;
				background-size: contain;
			}

			.grid-item {
				position: relative;
				text-align: center;
				// border: 1px solid #FFF;

				.circle {
					width: 160px;
					height: 160px;
					display: flex;
					justify-content: center;
					align-items: center;
					flex-direction: column;
					border-radius: 50%;
					color: #fff;
					border: 1px solid rgba(177, 183, 203, 0.3);
					background-clip: content-box;
					padding: 5px;
					font-family: var(--def-h-font);
					font-weight: bold;
				}

				.number {
					font-size: 2em;
				}

				.color-red-5 {
					background-color: var(--c-red-09);
				}

				.color-red-7 {
					background-color: var(--c-red-07);
				}

				.color-red-8 {
					background-color: var(--c-red-05);
				}

				.color-orange-1 {
					background-color: var(--c-red-03);
				}

				.color-orange-2 {
					background-color: var(--c-red-01);
				}

				h2 {
					color: #fff;
					text-align: center;
					text-transform: uppercase;
					font-family: var(--def-h-font);
				}

				p {
					font-family: var(--def-font);
					font-weight: 200;
					margin-bottom: 0;
				}
			}
		}
	}

	#section-animation {
		overflow-x: hidden;
		padding-top: 50px;

		& .box1,
		& .box2 {
			background-color: #fff;
			padding: 10px;
			position: relative;

			p {
				color: var(--c-black) !important;
				font-family: var(--def-h-font);
				font-size: 35px;
				font-weight: bold;
				margin: 0;
				text-align: center;
				text-transform: uppercase;
				overflow: hidden;
				white-space: nowrap;
				width: 200%;
				-webkit-animation: moveclouds 4s linear infinite;
				-moz-animation: moveclouds 4s linear infinite;
				-o-animation: moveclouds 4s linear infinite;

				@include media-breakpoint-only(xs) {
					width: 400;
				}
			}
		}

		.box1 {
			transform: rotate(2deg);
			position: relative;
			z-index: 1;
		}

		.box2 {
			transform: rotate(-2deg);
			opacity: 0.1;
			top: -70px;
			z-index: 0;
		}
	}

	#section3 {
		margin-top: 120px;

		& .sato-services-wrapper {
			height: 100%;

			@include media-breakpoint-only(xs) {
				display: grid;
				grid-auto-flow: column;
				grid-auto-rows: 1fr;
				grid-gap: 15px;
				margin-top: 60px;
				overflow-y: scroll;
			}

			& .sato-services-item {
				background: rgb(255, 255, 255);
				background: linear-gradient(180deg,
						rgba(255, 255, 255, 0) 0%,
						rgba(7, 11, 24, 1) 20%,
						rgba(7, 11, 24, 1) 80%,
						rgba(255, 255, 255, 0) 100%);
				height: 80vh;
				padding: 50px 0px;
				position: -webkit-sticky;
				position: sticky;
				top: 0;

				&:first-child {
					& .sato-services-body:before {
						background-image: linear-gradient(0deg, transparent, transparent 50%, #070b18 50%, #070b18 100%), linear-gradient(0deg, #070b18, #fff, #fff, #fff, #fff);
						height: 50%;
						top: 40%;
					}
				}


				@include media-breakpoint-only(xs) {
					align-content: start;
					background: var(--c-dark-06);
					border-radius: 20px;
					height: 100%;
					margin-right: 15px;
					padding: 40px;
					width: 75vw;
					position: unset;
				}

				&:first-child .sato-services-body:before {
					top: calc(44% - 40px);
					height: 50%;
				}

				& .sato-services-body {
					align-items: center;
					justify-items: center;
					display: grid;
					grid-auto-flow: column;
					grid-gap: 10%;

					@include media-breakpoint-only(xs) {
						padding: 0;
					}

					&:before {
						width: 1px;
						background-image: linear-gradient(0deg, transparent, transparent 50%, #070b18 50%, #070b18 100%), linear-gradient(0deg, #070b18, #fff, #fff, #fff, #070b18);
						background-size: 3px 20px, 3px 100%;
						border: none;
						content: "";
						display: block;
						height: 100%;
						left: 42px;
						position: absolute;
						opacity: 0.3;

						@include media-breakpoint-only(xs) {
							display: none;
						}
					}

					& .sato-services-content {
						width: 100%;

						& h3 {
							@include media-breakpoint-only(xs) {
								margin-bottom: 20px;
							}

							&>span {
								/*&:before{
                            background:000;
                            content:"";
                            display:block;
                            position:relative;
                        }*/
								background: linear-gradient(77deg, #e41b4b 0%, #d94f4f 100%);
								-webkit-background-clip: text;
								-webkit-text-fill-color: transparent;
								display: inline-block;
								font-family: var(--h-font-def);
								font-size: var(--f2-fs);
								font-weight: bold;
								width: 20%;

								@include media-breakpoint-only(xs) {
									display: block;
									margin-bottom: 40px;
									width: 100%;
								}
							}
						}

						p {
							margin-left: 20%;

							@include media-breakpoint-only(xs) {
								margin: 0;
								padding: 0;
							}
						}
					}

					& video {
						border-radius: 100%;
						max-height: 350px;
						max-width: 350px;

						@include media-breakpoint-only(xs) {
							display: none;
						}
					}
				}
			}
		}
	}

	#section4 {
		display: flex;

		&>div {
			flex: 1;

			&>div:first-child {
				display: flex;
				justify-content: space-between;
				flex-direction: column;

				&>p:last-child {
					margin: 0;
				}
			}
		}

		.container-customers {
			display: grid;
			grid-template-columns: auto auto auto auto;
			grid: auto / repeat(6, 1fr);
			justify-content: space-between;
			justify-items: center;
			grid-gap: 15px;
			/*margin-left: 80px;*/
			/*
			&:before{
				content:"";
				background:linear-gradient(180deg, rgba(7,11,24,1) 0%, rgba(255,255,255,0) 100%);
				display: block;
			}*/

			@include media-breakpoint-only(xs) {
				grid-auto-flow: column;
				margin-top: 40px;
				overflow-x: scroll;
				width: 100vw;
			}

			& .grid-item:nth-child(2n),
			& .grid-item:nth-child(2n-1) {
				grid-column: span 1;
			}

			.grid-item {
				aspect-ratio: 1/1;
				width: 100%;
				max-width: 100px;
				height: max-content;
				background-color: #0e1324;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 30px;
				text-align: center;
				padding: 5px;
				grid-column: span 2;

				img {
					border-radius: 100%;
				}

				@include media-breakpoint-only(xs) {
					height: 110px;
					width: 140px;

					img {
						height: 80%;
						width: 80%;
					}
				}
			}

			.middle {
				position: relative;
				left: -80px;
			}

			.opacity-3 {
				opacity: 0.3;
			}

			.opacity-5 {
				opacity: 0.5;
			}
		}

		@include media-breakpoint-only(xs) {
			.button-line {
				display: flex;
				flex-flow: column;
			}
		}
	}

	#section5 {
		/*margin: 150px 0px 100px 0px;*/
		min-height: max-content;
		padding: 100px 0px 150px 0px;
		position: relative;

		>.row>div.col-12 {
			align-items: center;
			display: flex;
			justify-content: center;
			position: relative;

			@include media-breakpoint-only(xs) {
				margin-bottom: 50px;
			}
		}

		@include media-breakpoint-only(xs) {
			margin: 0;
			padding: 60px 0px;
		}

		& div.row>div:nth-child(2) {
			display: flex;
			justify-content: center;
			flex-flow: column;

			@include media-breakpoint-only(xs) {
				padding: 0px 30px;
			}
		}

		.bg-stars {
			align-items: center;
			justify-content: center;
			background: url("../../../public/images/pages/home/stars.svg");
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
			display: flex;
			height: min-content;
			position: relative;
			padding: 50px;

			@include media-breakpoint-only(xs) {
				height: 100vw;
				height: 100vw;
			}
		}

		.bg-video-small {
			mix-blend-mode: exclusion;
			position: absolute;
			object-fit: cover;
		}

		.container-circle {
			position: relative;
			width: max-content;
			height: max-content;

			.container-border {
				border: 1px solid rgba(255, 255, 255, 0.2);
				border-radius: 100%;
				width: 600px;
				height: 600px;
				position: relative;

				@include media-breakpoint-only(xl) {
					width: 450px;
					height: 450px;
				}

				@include media-breakpoint-only(lg) {
					height: 400px;
					width: 400px;
				}

				@include media-breakpoint-only(md) {
					height: 300px;
					width: 300px;
				}

				@include media-breakpoint-only(xs) {
					height: 80vw;
					width: 80vw;
				}
			}
		}

		.circle {
			position: absolute;
			width: 350px;
			height: 350px;
			background-color: #070b18;
			border-radius: 50%;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);

			@include media-breakpoint-only(xl) {
				width: 300px;
				height: 300px;
			}

			@include media-breakpoint-only(lg) {
				width: 250px;
				height: 250px;
			}

			@include media-breakpoint-only(md) {
				width: 200px;
				height: 200px;
			}

			@include media-breakpoint-only(xs) {
				width: 250px;
				height: 250px;
			}

			& video {
				object-fit: cover;
				border-radius: 100%;
			}
		}

		.side-div,
		.top-div,
		.bottom-div {
			position: absolute;
			width: 100px;
			height: 100px;
			/* Dimensões ajustadas para igualar o espaçamento */
			background-color: #e74c3c;

			@include media-breakpoint-only(lg) {
				width: 80px;
				height: 80px;
			}

			@include media-breakpoint-only(md) {
				width: 60px;
				height: 60px;
			}
		}

		.left {
			background: url("../../../public/images/pages/home/ellipse34.svg");
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
			left: -50px;
			top: 50%;
			transform: translateY(-50%);

			/* Posicionamento ajustado para igualar o espaçamento */
			@include media-breakpoint-only(lg) {
				left: -40px;
			}

			@include media-breakpoint-only(md) {
				left: -39px;
			}
		}

		.right {
			background: url("../../../public/images/pages/home/ellipse25.svg");
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
			right: -50px;
			top: 50%;
			transform: translateY(-50%);

			@include media-breakpoint-only(lg) {
				right: -40px;
			}

			@include media-breakpoint-only(md) {
				right: -30px;
			}
		}

		.top-div {
			background: url("../../../public/images/pages/home/elipse24.webp");
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
			top: -50px;
			left: 50%;
			transform: translateX(-50%);

			@include media-breakpoint-only(lg) {
				top: -40px;
			}

			@include media-breakpoint-only(md) {
				top: -30px;
			}
		}

		.bottom-div {
			background: url("../../../public/images/pages/home/ellipse34.svg");
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
			bottom: -50px;
			left: 50%;
			transform: translateX(-50%);

			@include media-breakpoint-only(lg) {
				bottom: -40px;
			}

			@include media-breakpoint-only(md) {
				bottom: -30px;
			}
		}
	}

	#section6 {
		position: relative;
		margin-bottom: 100px;

		.swiper {
			padding-top: 40px;
		}

		.swiper-slide {
			display: flex;
			justify-content: center;
			align-items: center;

			@include media-breakpoint-only(xs) {
				opacity: 1 !important;
			}

			& .partner-container {
				aspect-ratio: 1/1;
				display: flex;
				justify-content: center;
				align-items: center;
				background: #fff;
				border-radius: var(--slide-partner-container-br);
				height: var(--slide-partner-container-size);
				width: var(--slide-partner-container-size);

				& img {
					max-width: 60%;
					max-height: 50%;
				}
			}
		}

		.swiper-pagination-bullets {
			display: none;
		}

		.swiper-button-disabled {
			opacity: 0.4;
		}

		.carousel-partner-home {
			&:before {
				content: '';
				background: linear-gradient(90deg, rgba(7, 11, 24, 0) 57%, rgba(7, 11, 24, 0.3) 58%, rgba(7, 11, 24, 0.3) 71%, rgba(7, 11, 24, 0.6) 72%, rgba(7, 11, 24, 0.6) 86%, rgba(7, 11, 24, 0.8) 87%, rgba(7, 11, 24, 0.8) 100%);
				display: block;
				position: absolute;
				height: 100%;
				width: 100%;
				z-index: 2;

				@include media-breakpoint-down(md) {
					background: none;
				}
			}
		}

		.pagination {
			position: absolute;
			top: 0;
			right: 8.33333333%;
			width: 100px;
			z-index: 4;

			@include media-breakpoint-only(xs) {
				bottom: -50px;
				left: 45px;
				top: auto;
			}
		}

		.swiper-button-next,
		.swiper-button-prev {
			background-color: var(--c-dark-07);
			background-position: center;
			background-repeat: no-repeat;
			background-size: 7px 12px;
			border-radius: var(--slide-partner-pagination-br);
			cursor: pointer;
			height: var(--slide-partner-pagination-btn-size);
			width: var(--slide-partner-pagination-btn-size);
		}

		.swiper-button-prev {
			background-image: url("data:image/svg+xml,%3csvg width='7' height='12' viewBox='0 0 7 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.74995 10.6199L1.94662 6.81655C1.49745 6.36738 1.49745 5.63238 1.94662 5.18322L5.74995 1.37988' stroke='white' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round'/%3e%3c/svg%3e");
			margin-right: 15px;
		}

		.swiper-button-next {
			background-image: url("data:image/svg+xml,%3csvg width='7' height='12' viewBox='0 0 7 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M1.19751 10.6199L5.00084 6.81655C5.45001 6.36738 5.45001 5.63238 5.00084 5.18322L1.19751 1.37988' stroke='white' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round'/%3e%3c/svg%3e");
		}

		.swiper-button-next::after,
		.swiper-button-prev::after {
			content: "";
		}
	}
}

/* MOBILE VIEWS */
/* Consulta de mídia para dispositivos móveis (até 768px) */

/* Consulta de mídia para tablets (de 769px a 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
	/* Você pode ajustar o layout para tablets aqui */
}

p.circle-number {
	color: #fff;
	position: absolute;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
	top: 47px;
}

@-moz-keyframes spin {
	100% {
		-moz-transform: rotate(360deg);
	}
}

@-webkit-keyframes spin {
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}