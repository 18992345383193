#franquias{
    .image-bg {
       img {
        position: absolute;
        top: 0;
       }
    } 

    .image-bg-right {
        img {
         position: absolute;
            right: 0;
            z-index: 0;
        }
     } 

    .container {
        display: flex;
        width: 100%;
        max-width: 1440px;
    }
    
    .section-01 {
        background-image: url('../../../public/images/pages/franquias/bg-inner.svg');
        background-position-x: right;
        background-size: 900px;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        padding-top: 0px;
        height: 1308px;
        .container {
            display: flex;
            width: 100%;
            .tittle {
                width: 53%;
                p {
                    font-weight: 100;
                    font-size: 2rem;
                }
                h2 {
                    font-weight: 600;
                    font-size: 3rem;
                    font-size: 40px;
                    color: #fff;
                }
                display: flex;
                flex-direction: column;
                .vermelho {
                    color: #E41B4B!important;
                    font-family: var(--def-h-font);
                    font-weight: 600;
                }
            }
            .content-01 {
                margin-left: 102px;
                display: flex;
                gap: 30px;
                position: relative;
                .box-01 {
                    padding: 20px;
                    width: 268px;
                    height: 272px;
                    background: rgba(29, 34, 50, 0.40);
                    backdrop-filter: blur(40px);
                    border-radius: 40px;
                    margin-bottom: 92px;
                    p {
                        font-size: 24px;
                        font-family: var(--def-font);
                    }
                }
                .box-02 {
                    padding: 20px;
                    width: 268px;
                    height: 302px;
                    background: rgba(29, 34, 50, 0.40);
                    backdrop-filter: blur(40px);
                    border-radius: 40px;
                    margin-top: 209px;
                    p {
                        font-size: 24px;
                        font-family: var(--def-font);
                    }
                }
                .tag-saiba-mais {
                    position: absolute;
                    bottom: 0;
                    margin-left: -200px;
                }
            }
        }
    }
    
    .section-02 {
        .container {
            margin-top: -300px;
            width: 100%;
            .video {
                background-image: url(../../../public/images/pages/franquias/stars.svg);
                background-repeat: no-repeat;
                background-size: contain;
                margin-left: -220px;
                width: 100%;
                height: 830px;
                max-width: 850px;
               img {
                position: absolute;
                left: 200px;
                top: 1160px;
                width: 500px;
               }
                .bg-video-small {
                    mix-blend-mode: exclusion;
                    object-fit: cover;
                    width: 100%;
                    max-width: 1500px;
                }
            }
            .content-02 {
                width: 50%;
                margin-left: 100px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                p {
                    &:first-child {
                        font-size: 24px;
                        font-weight: 300;
                        font-family: var(--def-font);
                    }
                }
            }
        }
    }
    
    .section-03 {
        text-align: center;
        .container {
            max-width: 1440px;
            .video-summit {
                width: 100%;
                min-width: 1440px;
                .video-full {
                    width: 1440px;
                    height: 900px;
                }
            }
        }
    }
    
    .section-04 {
        .container {
            padding-top: 104px;
            padding-bottom: 73px;
            max-width: 1440px;
            .tittle {
                h2 {
                    color: #fff;
                    line-height: 3.5rem;
                    font-size: 3rem;
                    span {
                        color: #E41B4B!important;
                        font-family: var(--def-h-font);
                    }
                }
            }
                .paragraph {
                    padding-left: 100px;
                    p {
                        line-height: 180%;
                        font-family: var(--def-font);
                    }
            }
        }
    }
    
    .section-05 {
        .container {
            gap: 80px;
            .column {
                &:nth-child(odd) {
                    margin-top: 80px;
                }
                .box {
                    padding: 20px;
                    width: 286px;
                    height: 248px;
                    background-color: rgba(9,14,32, 100%);
                    border-radius: 40px;
                    margin-bottom: 20px;
                    p {
                        font-size: 24px;
                        font-family: var(--def-font);
                    }
                }
            }   
        }
    }
    
    .section-5-5 {
        display: none;
    }
    .section-06 { 
        .container {
            align-items: center;
            justify-content: center;
            text-align: center;
        }
    }
    
    .section-07 {
        z-index: 1;
        .container {
            display: flex;
            padding-top: 50px;
            margin-top: 70px;
            .image-full {
                width: 100%;
                position: absolute;
                left: 0;
                img {
                    width: 40%;
                }
            }
            .content {
                display: flex;
                flex-direction: column;
                padding-left: 600px;
                width: 100%;
                padding-top: 200px;
                h2 {
                    color: #fff;
                    span {
                        color: #E41B4B;
                    }
                }
                h3 {
                    font-family: var(--def-font);
                    font-size: 24px;
                    color: #fff;
                    font-weight: 300;
                }
            }
        }
    }
    
    .section-08 {
        margin-top: 100px;
        h2 {
            color: #FFF;
            text-align: center;
            padding-bottom: 50px;
        }
    }
    
    .section-09 {
        padding-bottom: 100px;
        .container {
            gap: 50px;
        }
    }
    
    .section-99 {
        display: none;
    }
    
    
    @media (max-width: 768px) {
        body {
            overflow-x: hidden;
        }
        * {
            margin: 0;
            padding: 0;
            box-sizing: border-box;
        }
        
        ::-webkit-scrollbar {
            width: 0px;
            background: transparent; /* make scrollbar transparent */
        }
        
        .image-bg {
            display: none;
        }

        .image-bg-right {
            display: none;
        }
        
        .container {
            padding-left: 10px;
            padding-right: 10px;
        }

        .section-01 {
            background-position-x: 0;
            background-size: 400px!important;
            background-repeat: no-repeat;
            display: flex;
            align-items: center;
            padding-top: 0px;
            height: 808px;
            .container {
                display: flex;
                flex-direction: column;
                margin-top: 100px;
                .tittle {
                    width: 100%;
                    h2 {
                        font-size: 2rem;
                    }
                    a {
                        width: 357px;
                        font-size: 1rem;
                    }
                    .vermelho {
                        font-weight: 600!important;
                        color: #E41B4B!important;
                        font-family: var(--def-h-font)!important;
                    }
                }
                .content-01 {
                    margin-top: 50px;
                    width: 100%;
                    margin-left: 0px;
                    .box-01 {
                        width: 165px;
                        height: 200px;
                        padding: 10px;
                        p {
                            font-size: 0.9rem;
                        }
                    }
                    .box-02 {
                        margin-top: 0px;
                        font-size: 0.8rem;
                        width: 165px;
                        height: 200px;
                        padding: 10px;
                        p {
                            font-size: 0.9rem;
                        }
                    }
                }
            }
        }
    
        .section-02 {
            .container {
                margin-top: 0px;
                display: flex;
                flex-direction: column;
                .video {
                    margin-left: 0px;
                    width: 440px;
                    height: 370px;
                    margin-left: -50px;
                    img {
                        top: 875px!important;
                        left: 65px!important;
                        width: 230px;
                    }
                }
                .content-02 {
                    width: 100%;
                    margin-left: 10px;
                }
            }
        }
    
        .section-03 {
            .container {
                max-width: 390px;
                .video-summit {
                    min-width: 390px!important;
                    padding-top: 20px;
                    .ytp-cued-thumbnail-overlay-image {
                        background-image: url('../../../public/images/pages/franquias/video-overlay.svg')!important;
                    }
                    .video-full {
                        max-width: 390px;
                        height: 400px;
                    }
                }
            }
        }
    
        .section-04 {
            .container {
                display: flex;
                flex-direction: column;
                .tittle {
                    h2 {
                        color: #fff;
                        line-height: 3.5rem;
                        font-size: 3rem;
                        span {
                            color: #E41B4B;
                        }
                    }
                }
                    .paragraph {
                        padding-left: 0px;
                        p {
                            line-height: 180%;
                            font-family: var(--def-font);
                        }
                }
            }
        }
    
        .section-05 {
            display: none;
        }
    
        .section-5-5 {
            display: inline!important;
            .mySwiper {
                .swiper-slide {
                    width: 186px!important;
                    height: 250px;
                }
                .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
                    display: flex;
                    gap: 10px;
                    top: 219px;
                    margin-left: 100px;
                }
    
                .swiper-pagination-bullet {
                    background-color: #E41B4B;
                    font-size: 50px;
                    width: 18px;
                    height: 22px;
                }
                .box {
                    width: 165px;
                    height: 180px;
                    background-color: rgba(9,14,32, 100%);
                    border-radius: 40px;
                    align-items: center;
                    padding: 10px;
                    p {
                        margin-top: -20px;
                        font-size: 0.9rem;
                        font-family: var(--def-font);
                        padding-left: 10px;
                    }
                }
            }
        }
    
        .section-06 {
            .container {
                margin-top: 50px;
            }
        }
    
        .section-07 {
            .container {
                display: flex!important;
                flex-direction: column!important;
                padding-top: 50px;
                margin-top: 70px;
                .image-full {
                    width: 100%;
                    position: relative!important;
                    left: 0;
                    img {
                        width: 100%!important;
                    }
                }
                .content {
                    display: flex;
                    flex-direction: column;
                    padding-left: 0px!important;
                    width: 100%;
                    padding-top: 50px;
                    h2 {
                        color: #fff;
                        span {
                            color: #E41B4B;
                        }
                    }
                }
            }
        }
    
        .section-08 {
            display: none;
        }
        .section-09 {
            display: none;
        }
    
        .section-99 {
            padding-left: 20px;
            padding-top: 0px;
            h2 {
                color: #fff;
                padding-bottom: 50px;
                font-size: 2rem;
            }
            display: block;
            .swiper-slide {
                width: 186px!important;
                height: 250px;
            }
            .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
                top: 180px;
                margin-left: -100px;
            }
    
            .swiper-pagination-bullet {
                background-color: #E41B4B;
                font-size: 50px;
                width: 18px;
                height: 20px;
            }
        }
    
        
    }
    
    @media (max-width: 1550px) {

        .section-01 {
            .container {
                .vermelho {
                    font-weight: 600!important;
                    color: #E41B4B!important;
                    font-family: var(--def-h-font)!important;
                }
            }
        }
        .section-02 {
            .container {
                .video {
                    img {
                        bottom: -425px;
                        left: 30px;
                    }
                }
            }
        }
    
        .section-5-5 {
            display: none;
        }
    
        .section-07 {
            .container {
                .content {
                    padding-top: 50px;
                    padding-bottom: 150px;
                }
            }
        }
    }
    
    @media (max-width: 1366px) {
        .container {
            width: 100%;
            max-width: 1200px;
        }
        .section-01 {
            background-position-x: right;
        background-size: 700px;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        padding-top: 0px;
        height: 808px;
            .container {
                padding-top: 0px;
                .content-01 {
                    .tag-saiba-mais {
                        position: absolute;
                        bottom: 50px;
                        margin-left: -100px;
                    }
                }
            }
        }

        .section-02 {
            .container {
                .video {
                    img {
                        left: 50px;
                        top: 660px;
                    }
                }
            }
        }
    
        .section-03 {
            .container {
                max-width: 1221px   ;
            }
            .container {
                .video-summit {
                    width: 100%;
                    min-width: 1200px;
                    .video-full {
                        width: 1200px;
                        height: 600px;
                    }
                }
            }
        }
    
        .section-04 {
            .container {
                max-width: 1212px;
            }
        }
    
        .section-05 {
            .container {
                gap: 20px;
                margin-left: 6%;
            }
        }
    
        .section-07 {
            .container {
                display: flex;
                padding-top: 50px;
                margin-top: 70px;
                .image-full {
                    width: 100%;
                    position: absolute;
                    left: 0;
                    img {
                        width: 40%;
                    }
                }
                .content {
                    display: flex;
                    flex-direction: column;
                    padding-left: 500px;
                    width: 100%;
                    padding-top: 50px;
                }
            }
        }
        .section-09 {
            .container {
                gap: 24px;
            }
        }
    }

}
